import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkSessionAPI, postLogIn } from "./apis";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const checkUserSessionAction = createAsyncThunk(
  "Auth/checkUserSession",
  async () => {
    const res = await checkSessionAPI();
    if (res.status === 204) throw new Error("Session is not valid");
    return res;
  }
);

export const addLogIn = createAsyncThunk("login/loginDOc", async (payload) => {
  const resultPromise = new Promise((resolve, reject) => {
    postLogIn(payload)
      .then((res) => {
        localStorage.setItem("AdminHeaven", res.data?.token);
        resolve(res.data);
      })
      .catch(({ response }) => {
        reject(response.data?.message || "Something went wrong");
      });
  });

  toast.promise(resultPromise, {
    loading: "Signing...",
    success: "Signed In successfully",
    error: (err) => err,
  });

  return await resultPromise;
});
