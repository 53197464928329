import React, { useEffect, useState } from "react";
import { SlArrowUp } from "react-icons/sl";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div className="scroll-to-top">
      {isVisible && (
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          className="arrow"
          onClick={scrollToTop}
        >
          <SlArrowUp style={{ color: "black", width: "120px" }} />
        </div>
      )}
    </div>
  );
};

export default ScrollToTop;
