import { makeStyles } from "@material-ui/core";
import React from "react";
import img from "../../assets/aboutimg.webp";

export default function StoriesHeroSection() {
  const classes = useStyles();
  return (
    <section className={classes.main}>
      <div className={classes.inner}>
        <div className={classes.textSection}>
          <p className={classes.text}>AUTHENTIC, EFFORTLESS </p>
          <p className={classes.text}> &</p>
          <p className={classes.text}>AESTHETIC IMAGERY</p>
        </div>
        <div className={classes.separator} />
        <div className={classes.imageContainer}>
          <img src={img} alt="" className={classes.image} />
        </div>
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "100px 10px 120px 10px",
    backgroundColor: "#ede9e0",
    display: "flex",
    justifyContent: "center",
  },
  inner: {
    display: "flex",
    columnGap: 60,
    alignItems: "center",
    marginLeft: "30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      rowGap: 60,
      marginLeft: "0px",
    },
  },
  textSection: {
    width: "463px",
    height: "auto",
    padding: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      width: "100%",
    },
  },
  text: {
    fontSize: "31px",
    marginBottom: "15px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    color: "rgba(102, 102, 102, 1)",
    fontWeight: "400",
    fontFamily: 'Silk Serif ExtraLight, serif',
    letterSpacing: '0.07em'
  },
  separator: {
    backgroundColor: "#000",
    width: 1,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "419px",
    height: "494px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  caption: {
    display: "flex",
    flexDirection: "column",
    textAlign: "end",
    paddingTop: 8,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  headFounder: {
    paddingTop: "10px",
  },
  founderName: {
    textTransform: "uppercase",
    margin: 0,
  },
}));
