import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Modal } from "react-bootstrap"; // Import Modal from react-bootstrap
import "./styles.css";

import { BASE_URL } from "../../http/urls";

const ReactMasonry = ({ data = [] }) => {
  const [zoomedImage, setZoomedImage] = useState(null); // State for the zoomed image

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl); // Set the clicked image URL to the state
  };

  const handleClose = () => {
    setZoomedImage(null); // Clear the zoomed image state
  };

  return (
    <div className="masnoryContainer container mt-5 pt-6 mb-5 pb-5">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={3} gutter="10px">
          {data.length > 0 ? (
            data.map((item, key) => {
              return (
                <div
                  key={key}
                  className="masonryItem"
                  onClick={() => handleImageClick(`${BASE_URL}/${item.imageUrl}`)}
                >
                  <img
                    src={`${BASE_URL}/${item.imageUrl}`}
                    style={{ width: "100%", display: "block", cursor: "pointer" }} // Add cursor pointer for better UX
                    alt=""
                  />
                </div>
              );
            })
          ) : (
            <p>No images to display</p> // Fallback when no images
          )}
        </Masonry>
      </ResponsiveMasonry>

      {/* Modal for zoomed image */}
      <Modal
        show={!!zoomedImage}
        onHide={handleClose}
        centered
        className="custom-modal" // Add a custom class for the modal
      >
        <Modal.Body className="text-center custom-modal-body">
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
          <img
            src={zoomedImage}
            style={{ width: "100%", height: "auto" }} // Responsive image in modal
            alt=""
          />
        </Modal.Body>
      </Modal>

      {/* Overlay for black transparent background */}
      {zoomedImage && <div className="modal-overlay" onClick={handleClose} />}
    </div>
  );
};

export default ReactMasonry;
