import React from "react";

import { makeStyles } from "@material-ui/core";

export default function AddoN() {
  const classes = useStyles();
  const Data = [
    {
      Number: "01",
      TextHeading: "Extra hours",
      desc: `Need additional coverage beyond our standard packages? We understand
  that every wedding is unique, and so are the moments you want
  captured. Additional hours can be accommodated on a case-by-case
  basis, tailored to your specific event, timing, and requirements.
  Please don't hesitate to inquire for additional information on
  extending your coverage to ensure every cherished moment is preserved`,
      textEnd: "Inquire",
    },
    {
      Number: "02",
      TextHeading: "Extra hours",
      desc: `Need additional coverage beyond our standard packages? We understand
    that every wedding is unique, and so are the moments you want
    captured. Additional hours can be accommodated on a case-by-case
    basis, tailored to your specific event, timing, and requirements.
    Please don't hesitate to inquire for additional information on
    extending your coverage to ensure every cherished moment is preserved`,
      textEnd: "Inquire",
    },
    {
      Number: "03",
      TextHeading: "Extra hours",
      desc: `Need additional coverage beyond our standard packages? We understand
    that every wedding is unique, and so are the moments you want
    captured. Additional hours can be accommodated on a case-by-case
    basis, tailored to your specific event, timing, and requirements.
    Please don't hesitate to inquire for additional information on
    extending your coverage to ensure every cherished moment is preserved`,
      textEnd: "Inquire",
    },
    {
      Number: "04",
      TextHeading: "Extra hours",
      desc: `Need additional coverage beyond our standard packages? We understand
    that every wedding is unique, and so are the moments you want
    captured. Additional hours can be accommodated on a case-by-case
    basis, tailored to your specific event, timing, and requirements.
    Please don't hesitate to inquire for additional information on
    extending your coverage to ensure every cherished moment is preserved`,
      textEnd: "Inquire",
    },
  ];
  return (
    <section className={classes.main}>
      <div className={classes.inner}>
        <div className={classes.headingSection}>
          <p style={{color:"black", fontSize:'1rem'}}>Y O U R    M E M O R I E S</p>
          <h3 style={{color:'black', textTransform:'uppercase', fontSize:'3rem'}}>
            add on's
          </h3>
        </div>

        <div className={classes.gridSection}>
          {Data.map(({ ...item }, i) => {
            return <Card item={item} key={i} />;
          })}
        </div>
      </div>
    </section>
  );
}

export const Card = ({ item }) => {
  const classes = useStyles();
  const { Number, TextHeading, desc, textEnd } = item;
  return (
    <section className={classes.mainCard}>
      <div style={{ padding: "0px 30px", width: 200 }}>
        <p style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}>
          {" "}
          {Number}{" "}
        </p>
      </div>
      <div className={classes.dataMid}>
        <p style={{ fontSize: 28, fontWeight: "normal", textAlign: "center" }}>
          {TextHeading}
        </p>
        <p style={{ fontSize: 18, fontWeight: "normal", padding: "0px 50px" }}>
          {desc}
        </p>
      </div>
      <div style={{ width: 600, textAlign: "center", paddingTop: 10 }}>
        <h2>{textEnd}</h2>
      </div>
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 20,
    marginBottom: 20,
    width: "70vw",
    margin: "0 auto",
  },
  inner: {
   
    padding: "60px 0px",
    borderRadius: "20px",
  },
  headingSection: {
    textAlign: "center",
    fontFamily: 'CustomFont3',
    color: 'rgb(144, 144, 144)',
    textTransform: 'none',
    fontSize: '24px',
    marginTop: '50px',
  },
  mainCard: {
    backgroundColor: "#E9EAEC",
    padding: "1rem 1.5rem",
    margin:'2rem ',
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dataMid: {
    textAlign: "center",
  },
}));
