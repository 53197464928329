import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDocuments } from "./api";

export const allDocument = createAsyncThunk(
  "allInstagramPosts/docs",
  async () => {
    try {
      const res = await getAllDocuments();
      console.log("asdfhaskfdk",res.data)
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);
