import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import Horizontal from "../../assets/new/aboutBanner.jpg";
import { meta } from "../../content_option";
import Footer from "../../components/footer";
import bgGiff from "../../assets/video/Gauri1.mp4";
import FollowUs from "../../components/followUs";
import Collab from "../../components/collab";
import TestimonalComponent from "../../components/testimonal";
import { useScrollToTop } from "../../hooks/useScroll";
import { Headermain } from "../../header";
import { StoryTellers1 } from "../../components/storyTellers";
import AboutTextSection from "../../components/aboutTextSection";
import AboutHeroSection from "../../components/aboutHeroSection";
import ImageHorizontal from "../../components/imageHorizontal";
export const About = () => {
  useScrollToTop();
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title> About | {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Headermain />

      <div className="aboutContainer  ">
        <AboutHeroSection />
        <AboutTextSection />
        <ImageHorizontal Src={Horizontal} Height={"500px"}  rotate={true} />
        {/* <Collab  /> */}

        {/* <StoryTellers1 /> */}
        {/* <Featured /> */}
        <FollowUs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};
