import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import { useScrollToTop } from "../../hooks/useScroll";
import { HeaderDark } from "../../header";
import "./styles.css";
import Footer from "../../components/footer";
export const Education = () => {
  useScrollToTop();
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Education | {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <HeaderDark />
      <div className=" comingsoon mt-5 pt-7 ">
        <h3 className="km ">Coming Soon...</h3>
      </div>
     <Footer/>
    </HelmetProvider>
  );
};
