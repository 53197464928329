import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import AOS from "aos";
import "aos/dist/aos.css";
import Quote from "../../components/quote";
import { StoryTellers2 } from "../../components/storyTellers";
import FollowUs from "../../components/followUs";
import bg1 from "../../assets/2.webp";
import bg2 from "../../assets/1.webp";
import bg3 from "../../assets/3.webp";
import bg4 from "../../assets/4.webp";
import bg5 from "../../assets/5.webp";
import bg6 from "../../assets/6.webp";
import { SlArrowDown } from "react-icons/sl";
import { useScrollToTop } from "../../hooks/useScroll";
import { Headermain } from "../../header";
import TestimonalComponent from "../../components/testimonal";
import Footer from "../../components/footer";
import Moments from "../../components/moments";

const backgrounds = [bg1, bg2, bg3,  bg4, bg5, bg6];

export const Home = () => {

  useScrollToTop();
  const [activeBg, setActiveBg] = useState(-1);
  const [showImages, setShowImages] = useState(false);


  useEffect(() => {
    AOS.init({ duration: 1000 });

    const initialTimeout = setTimeout(() => {
      setShowImages(true); // Allow images to show after 1.5 seconds
      setActiveBg(0); // Load the first image after images are shown
      const interval = setInterval(() => {
        setActiveBg((prev) => (prev + 1) % backgrounds.length);
      }, 5000);
      return () => clearInterval(interval);
    }, 200); // 1.5 seconds delay before showing images

    return () => clearTimeout(initialTimeout);
  }, []);



  const handleArrowClick = () => {
    window.scroll({
      top: (window.innerHeight * 3) / 4,
      behavior: "smooth",
    });
  };

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Headermain />
      <section id="home" className="home">
        <div className="bgContainer" data-aos="fade-in" data-aos-duration="1000">
          {backgrounds.map((bg, index) => (
            <img
              key={index}
              className={`bg ${index === activeBg ? "active" : ""}`}
              src={bg}
              alt=""
            />
          ))}
        </div>


        <div
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          className="arrow"
          onClick={handleArrowClick}
        >
          <SlArrowDown />
        </div>
      </section>
      <StoryTellers2 />
      <Moments />

      <TestimonalComponent />
      <FollowUs />
      <Footer />
    </HelmetProvider>
  );
};
