import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import { useScrollToTop } from "../../hooks/useScroll";
import { HeaderDark, Headermain } from "../../header";
import "./style.css";
import { Box } from "@material-ui/core";
import Footer from "../../components/footer";
import FollowUs from "../../components/followUs";

export const Afirmations = () => {
  useScrollToTop();

  const affirmations = [
    {
      message: [
        "Choosing Jag Photos as our wedding photographer was one of the best decisions we made for our big day. From the initial consultation to receiving our stunning photos, every step of the process was seamless and enjoyable.",
        "Jag Photos has an incredible talent for capturing the most precious moments in a way that is both artistic and heartfelt. Their attention to detail ensured that every shot was perfect, and they truly went above and beyond to make sure we were comfortable and relaxed throughout the entire shoot.",
        "Not only did Jag Photos capture the essence of our love story, but they also managed to capture the emotions and atmosphere of the day beautifully. Looking through our wedding album, we are transported back to each moment, experiencing the joy, laughter, and love all over again.",
        "We are endlessly grateful to Jag Photos for their professionalism, creativity, and passion for their craft. They have provided us with memories that we will cherish for a lifetime, and we cannot recommend them highly enough to anyone in search of a talented and dedicated wedding photographer.",
        "", // Empty string for line break
        "Thank you, Jag Photos, for exceeding our expectations and for being such an integral part of our special day.",
      ],
      signature: "Shaleena & Chris",
    },
    {
      message: [
        "We took a look at all of the wedding photos and we can't express how happy we are with them. You and your team did such an amazing job in capturing our precious moments that we will cherish forever. We are in absolute awe of all the photos. Thank you so much. You made us feel so comfortable during all shoots, especially the wedding shoot during the heatwave! We appreciate all your hard work.",
      ],
      signature: "Ricky & Simran",
    },
    {
      message: [
        "Working with Jag Photography was one of the most pleasant experiences from our wedding. They were professional, reliable and attentive to detail. They made us feel comfortable, especially during awkward ‘center of attention’ type moments. Jag and his team bring a sense of quality not only to the photos but also with their level of service. We are extremely happy with the photos from all of our wedding events – these are memories that will last a lifetime because Jag Photography was able to capture every candid moment. The only actual struggle was selecting photos for our photo album because they were all so beautiful! Thank you, Jag Photography.!",
        "", // Empty string for line break

      ],
      signature: "Ramneet  & Ave",
    },
    {
      message: [
        "Jag and Jas were incredible throughout our entire wedding week, very punctual and professional in capturing our families. They provided feedback and suggestions when needed to ensure they captured all of the important moment. This duo was fun and easy to work with they truly felt like family by the end of the week. Thank you so much we love all our photos.",
      ],
      signature: "Sandy  & Pardip",
    },
    {
      message: [
        "We took a look at all of the wedding photos and we can't express how happy we are with them. You and your team did such an amazing job in capturing our precious moments that we will cherish forever. We are in absolute awe of all the photos. Thank you so much. You made us feel so comfortable during all shoots, especially the wedding shoot during the heatwave! We appreciate all your hard work. ",
      ],
      signature: "Ricky  ran & Simran",
    },
    {
      message: [
        "Jag and his Team were nothing but professionals. They were so passionate about our wedding, you would have thought they were our family members. Their artistic vision and genuine empathy, makes them the logical choice for weddings. To date, we stay in touch with them and are planing to invite them to future events not only as a vendor but as a family member. They are true professionals and helped captured some really special moments. We love you and the family, Jag! ",
      ],
      signature: "Resh & Andy",
    },

  ];

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kind Words| {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Headermain />
      <Box className="affirmation-container pt-5 mt-5">
        <h3 className="km pb-5 mb-5">Kind Words</h3>
        {affirmations.map((affirmation, index) => (
          <div key={index} className="affirmation">
            {affirmation.message.map((line, idx) => (
              <React.Fragment key={idx}>
                <h4
                  style={{
                    fontFamily: "CustomFont3",
                    textTransform: "none",
                    color: "rgb(144, 144, 144)",
                  }}
                >
                  {line}
                  <br />
                </h4>
              </React.Fragment>
            ))}
            <p
              style={{
                fontFamily: "CustomFont",
                color: "rgb(79, 79, 79)",
              }}
              className="signature"
            >
              {affirmation.signature}
            </p>
            {/* Uncomment if you want to add a horizontal line between affirmations */}
            {/* {index < affirmations.length - 1 && <hr />} */}
          </div>
        ))}
      </Box>
      <FollowUs />
      <Footer />
    </HelmetProvider>
  );
};
