const QAdata = [
  {
    questions: [
      {
        question: "How many photographers will be present on our wedding day?",
        answer:
          "It depends on the package you choose and the size of your event. We offer options for one or multiple photographers to ensure comprehensive coverage.",
      },
      {
        question: "Can we request specific shots or poses?",
        answer:
          "Absolutely! We encourage you to share your vision and any specific requests you have. We'll work closely with you to ensure we capture all your desired moments.",
      },
      {
        question: "Do you offer videography?",
        answer: "Please inquire for more information.",
      },
      {
        question: "Are you available for destination weddings?",
        answer:
          "Yes, we love to travel! We're available for destination weddings worldwide. Additional travel fees may apply, depending on the location.",
      },
      {
        question: "How do we secure our wedding date with you?",
        answer:
          "To secure your wedding date, we require a signed contract and a non-refundable deposit. Once received, your date will be officially reserved on our calendar.",
      },
    ],
  },
  // {
  //   topic: "Travel",
  //   questions: [
  //     {
  //       question: "Where are you based? Do you travel for weddings?",
  //       answer:
  //         "We are based in Vancouver, Canada and do travel for weddings. We have documented weddings in various locations.",
  //     },
  //   ],
  // },
  // {
  //   topic: "Pricing & Delivery",
  //   questions: [
  //     {
  //       question: "How do you deliver films?",
  //       answer:
  //         "We deliver films on 2 USB sticks and an online link which is valid for 3 weeks. It is important to backup and save your investment.",
  //     },
  //     {
  //       question: "Do you provide Raw footage?",
  //       answer: "No, we do not provide Raw footage.",
  //     },
  //     {
  //       question: "When do we get our wedding films?",
  //       answer:
  //         "Wedding films are delivered within 3-4 months, with communication in special cases where more time is needed.",
  //     },
  //     {
  //       question: "How long do you keep a copy of our Wedding footage?",
  //       answer:
  //         "We keep a copy for 45 days after final delivery, after which it will be deleted.",
  //     },
  //   ],
  // },
  // {
  //   topic: "Booking & Contact",
  //   questions: [
  //     {
  //       question:
  //         "Woo-hoo, we are now more excited than ever, we are ready to book, what do we do now?",
  //       answer:
  //         "Book a consultation with us to go in depth about your wedding and secure your booking.",
  //     },
  //   ],
  // },
];

export default QAdata;
