import React from "react";
import "./App.css";
import img from "../assets/PNG For Water Marks/GMArtboard 2 copy.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Preloader = () => {
  React.useEffect(() => {
    AOS.init({
      easing: "ease-out",
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "23px",
        backgroundColor: "#201f0f",
      }}
    >
      <div data-aos="zoom-out" data-aos-duration="1500">
        <img src={img} alt="" className="preLoaderImg" />
      </div>
    </div>
  );
};

export default Preloader;
