import React, { useEffect } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { allDocument } from "../../features/instagram/instagram.action";
import { CircularProgress, Box } from "@material-ui/core";
import InsideWrapper from "../insideWrapper";

const FollowUs = () => {
  const dispatch = useDispatch();
  const { allDoc, allDocLoading, allDocLoadingSucess } = useSelector(
    (state) => state.instagramSlicer
  );

  useEffect(() => {
    dispatch(allDocument());
  }, [dispatch]);

  return (
    <InsideWrapper>
      <div className=" container followContainer mt-5 mb-5 ">
        <p
          className="mb-2 link followSection"
          style={{
            textTransform: "uppercase",
            color: "#646464",
            fontFamily: 'Quarto Light, serif',
            fontWeight: 400,
            fontStyle: " normal",
            fontSize: "9px",
            letterSpacing: ".3em",
            textTransform: "uppercase ",
          }}
        >
          Follow our work <strong className="space"></strong>{" "}
          <a
            href="https://www.instagram.com/jagphotostudios"
            className="instagram-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            @jagphotostudios
          </a>
        </p>
        {allDocLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "1em",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div className="imageDiv">
          {allDocLoadingSucess &&
            allDoc.length > 0 &&
            allDoc.map((item, index) => {
              return (
                <img
                  src={item?.media_url}
                  alt=""
                  className="followImg"
                  key={index}
                />
              );
            })}
        </div>
      </div>
    </InsideWrapper>
  );
};

export default FollowUs;
