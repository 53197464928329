import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import InvesmentsCardA from "./cardA";
import { useSelector } from "react-redux";

export default function InvestmentHead() {
  const classes = useStyles();
  const { allDoc, allDocLoading } = useSelector((state) => state.planSlicer);

  return (
    <section className={classes.contMain}>
      <div className={classes.heading}>INVESTMENTS</div>
      <div className={classes.content}>
        <p className={classes.paragraph}>
          Wedding photography is one of the most important investments of
          someone's life. Selecting the right photographer to document your
          special moments is challenging. We make this process comfortable for
          our clients by offering several creative consultations. We strive to
          make our client's experience as comfortable and memorable as possible.
        </p>
      </div>
      <div className={classes.content}>
        <p className={classes.paragraph}>
          A few other aspects that set iNavphoto apart from others. We dedicate
          a lot of effort into each wedding. We are just a text message away
          from our clients. We don't outsource our editing process. Every photo
          we edit receives special love and attention that reflects our unique
          style.
        </p>
      </div>
      {allDocLoading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.cards}>
          {allDoc && allDoc.slice(0, 3).map((item) => (
            <InvesmentsCardA key={item.id} item={item} />
          ))}
        </div>
      )}
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  contMain: {
    padding:"5rem 10vw"
  },
  heading: {
    padding: theme.spacing(12, 0),
    fontSize: '30px',
    textAlign: "center",
    fontFamily: 'Silk Serif ExtraLight, serif',
    textTransform: 'none',
    color: 'rgb(144, 144, 144)',
  },
  content: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    fontFamily: 'Quarto Light, serif',
    textTransform: 'none',
    fontSize: '18px',
    color: 'rgb(144, 144, 144)',
    maxWidth: "70vw",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  cards: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: theme.spacing(7),
    marginTop:"7rem",

  },
  loader: {
    display: "flex",
    justifyContent: "center",
  },
}));
