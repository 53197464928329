import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import logo from "../../assets/PNG For Water Marks/GMArtboard 2.png";
import InnerWrapper from "../../components/insideWrapper";
import ReactMasonry from "../../components/masnory";
import { useScrollToTop } from "../../hooks/useScroll";
import { HeaderDark, Headermain } from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDocument } from "../../features/imageUpload/imageUpload.action";
import { BASE_URL } from "../../http/urls";
import FollowUs from "../../components/followUs";
import Footer from "../../components/footer";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
    },
  },
  cardTitle: {
    textAlign: "center",
    fontFamily: 'Silk Serif ExtraLight, serif',
    fontSize: "80px",
    fontWeight: "400",
    color: "rgb(79, 79, 79)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "CustomFont",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.text.primary}`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      borderRight: "none",
    },
  },
  descriptionBox: {
    padding: theme.spacing(1),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    color: "rgba(102, 102, 102, 1)",
    fontWeight: "400",
    fontFamily: 'Quarto Light, serif',
  },
  imageContainer: {
    width: "100%",
    height: "500px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(5),
  },
}));

const StoryDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleDoc, singleDocLoading, singleDocLoadingSucess } = useSelector(
    (state) => state.imageUpload
  );

  useScrollToTop();

  useEffect(() => {
    if (id) {
      dispatch(getSingleDocument(id));
    }
  }, [dispatch, id]);

  return (
    <>
      <Headermain />
      {singleDocLoadingSucess && (
        <Box className={classes.imageContainer}>
          <img
            src={singleDoc && BASE_URL + singleDoc.coverImage}
            alt={"title"}
            className={classes.image}
          />
        </Box>
      )}

      <Box className={classes.detailsContainer}>
        <Box className="cntnr mb-5">
          <Typography variant="h3" className={classes.cardTitle}>
            {singleDoc?.title}
          </Typography>
        </Box>

        <InnerWrapper>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              color: "#77777",
            }}
          >
            {/* <Box className={classes.contentBox}>
              <Box>{singleDoc?.title}</Box>
              <Box style={{ textAlign: "center" }}>
                <img src={logo} alt="logo" className={classes.logo} />
              </Box>
            </Box> */}

            <Box className={classes.descriptionBox}>
              {singleDoc?.description}
            </Box>
          </Box>
        </InnerWrapper>

        <Box className={classes.circularProgress}>
          {singleDocLoading && <CircularProgress />}
        </Box>
        {singleDocLoadingSucess && singleDoc && (
          <ReactMasonry data={singleDoc && singleDoc.images} />
        )}
      </Box>
      <FollowUs />
      <Footer />
    </>
  );
};

export default StoryDetails;
