import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../http/urls";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    // margin: "20px",
    transition: "transform 0.3s",

    "&:hover": {
      transform: "scale(1.02)",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  img: {
    width: "100%",
    height: "auto",
    maxWidth: "445px",
    maxHeight: "581px",
    objectFit: "cover",
    borderRadius: "8px", // Optional: adds rounding to the corners
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
    },
  },
  divTitle: {
    padding: "220px 10px",
    backgroundColor: "#fff",
    opacity: 0,
    position: "absolute",
    textAlign: "center",
    top: "50px",
    left: "50px",
    right: "50px",
    bottom: "50px",
    transition: "opacity 0.3s",

    "&:hover": {
      opacity: 0.8,
    },

    [theme.breakpoints.down("md")]: {
      padding: "150px 10px",
      top: "20px",
      left: "20px",
      right: "20px",
      bottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "100px 10px",
      top: "10px",
      left: "10px",
      right: "10px",
      bottom: "10px",
    },
  },
  text: {
    borderRight: "2px solid #c6884a",
    padding: "1em",
    [theme.breakpoints.down("md")]: {
      padding: "0.8em",
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.6em",
      fontSize: "0.8rem",
    },
  },
  typographyTitle: {
    fontFamily: "'Playfair Display', sans-serif",
    padding: "7px 0px",
    fontSize: "1.45rem",
    color: "#c6884a",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const StoryCard = ({ coverImage, title, location, _id,frontImage }) => {
  const classes = useStyles();
// console.log(BASE_URL + coverImage,"ff");

  return (
    <div className={classes.cardContainer}>
      <Link to={`/storyDetails/${_id}`}>
        <img src={`${BASE_URL}/${frontImage}`} alt="" className={classes.img} />
        <div className={classes.divTitle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" className={classes.text}>
              {title}
            </Typography>
            <Typography variant="body1">{location}</Typography>
          </Box>
        </div>
      </Link>
    </div>
  );
};

export default StoryCard;
