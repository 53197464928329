import React, { useState } from "react";
import { Link } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import img1 from "../../assets/homeslider/JPS-146.webp";
import img2 from "../../assets/homeslider/JPS-167-2.webp";
import img3 from "../../assets/homeslider/JPS-227.webp";
import img4 from "../../assets/homeslider/JPS-76.webp";
import img5 from "../../assets/homeslider/4.webp";
import img6 from "../../assets/homeslider/5.webp";
import img7 from "../../assets/homeslider/6.webp";
import img8 from "../../assets/homeslider/7.webp";
import img9 from "../../assets/homeslider/8.webp";
// import img5 from "../../assets/sliderImages/slider5.JPG";
// import img6 from "../../assets/sliderImages/slider6.JPG";
import "./style.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9]; // Repeating the images

export const StoryTellers2 = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="storyTellerContainer1 pt-5 pb-5 mt-5 mb-5"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-easing="ease-in-sine"
    >
      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
        className="image-slider"
      >
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt={`Slide ${index}`} className="image" />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <div className="paras">
        <p className="Sp Sp1 text-xl font-semibold tracking-wide uppercase mb-6">
          WE AIM TO CREATE AUTHENTIC, EFFORTLESS & AESTHETIC IMAGERY, THAT REFLECT YOUR TRUE SELF
        </p>
        <p className="Sp Sp2 text-sm leading-relaxed mb-6">
          Let us capture your story for you. For us, photography is about connecting with and
          documenting an experience for people to come back to time and again. We take photographs
          so you can remember them and so each photograph can help you intimately relive each moment.
        </p>

        <Link
          to="/contact"
          className="km"
          style={{
            fontFamily: 'Quarto Light, serif',
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "normal",
            color: isHovered ? "#C6884A" : "rgb(79, 79, 79)",
            textDecoration: "none",
            cursor: "pointer",
            transition: "color 0.3s ease",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Enquire Now
        </Link>
      </div>
    </div>
  );
};
