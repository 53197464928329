import { FaPlay } from "react-icons/fa";
import img1 from "./assets/new/1.jpg";

import img2 from "./assets/new/img2.jpg";
import img3 from "./assets/new/3.jpg";
import img4 from "./assets/new/img4.jpg";
import img5 from "./assets/new/5.jpg";
import img6 from "./assets/new/img6.jpg";
import img7 from "./assets/new/img7.jpg";
import img8 from "./assets/new/8.jpg";
import img9 from "./assets/new/9.jpg";
import img10 from "./assets/new/img10.jpg";

import story1 from "./assets/new/story1.jpg";
const logotext = "JAG Photo Studios";
const meta = {
  title: "JAG Photo Studios",
  description:
    "JAG Photo Studios offers professional wedding photography services to capture the special moments of your big day.",
};

const images = [
  {
    id: 1,
    src: img1,
  },
  {
    id: 2,
    src: img2,
  },
  {
    id: 3,
    src: img3,
  },
  {
    id: 4,
    src: img4,
  },
  {
    id: 5,
    src: img5,
  },
  {
    id: 6,
    src: img6,
  },
  {
    id: 7,
    src: img7,
  },
  {
    id: 8,
    src: img8,
  },
  {
    id: 9,
    src: img9,
  },

  {
    id: 10,
    src: img10,
  },

  // {
  //   id: 12,
  //   src: img10,
  // },

  // {
  //   id: 13,
  //   src: img10,
  // },

  // {
  //   id: 14,
  //   src: img10,
  // },

  // {
  //   id: 15,
  //   src: img10,
  // },

  // {
  //   id: 16,
  //   src: img10,
  // },

  // {
  //   id: 17,
  //   src: img10,
  // },
];

const introdata = {
  title: "JAG Photo Studios",
  animated: {
    first: "We craft your visual love story",
    second: "Eternizing your memories, one frame",
    third: "Timeless art from your fairytale",
  },
  description:
    "Our team of experienced photographers use high-end equipment and techniques to create beautiful photographs that will be treasured for years to come. We offer a wide range of services including pre-wedding photoshoots, candid photography, traditional photography, and videography. We also provide a range of packages and options to suit different budgets and styles. By choosing us, you can trust that your wedding memories will be captured in the most beautiful and timeless way possible. The main focus of our service is on capturing the emotions, love and memories of the day in a creative and visually stunning way, so that you can cherish these memories for a lifetime ",
  // your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
  title: "a bit about my self",
  aboutme:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies.",
};

const process = [
  {
    icon: <FaPlay />,
    step: "the beginning",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];
const worktimeline = [
  {
    jobtitle: "Designer of week",
    where: "YAdfi",
    date: "2020",
  },
  {
    jobtitle: "Designer of week",
    where: "Jamalya",
    date: "2019",
  },
  {
    jobtitle: "Designer of week",
    where: "ALquds",
    date: "2019",
  },
];

const skills = [
  {
    name: "Work Ethics",
    value: 99,
  },
  {
    name: "Quality Equipment",
    value: 100,
  },
  {
    name: "Reliability",
    value: 100,
  },
  {
    name: "Highlights",
    value: 97,
  },
  {
    name: "Recomendations",
    value: 95,
  },
];

const services = [
  {
    title: "Wedding Photoshoots",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Event Management",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Destination shoots",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
];

const stories = [
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
  {
    img: story1,
    title: "Harpreet & Taruna",
    para: "We are the storytellers who are passionate about capturing your love stories with authenticity and elegance, without compromising on individuality and tradition in your life’s most extraordinary moments.",
  },
];

const contactConfig = {
  YOUR_EMAIL: "name@domain.com",
  YOUR_FONE: "(555)123-4567",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  // github: "https://github.com",
  instagram: " https://www.instagram.com/jagphotostudios/?hl=en",
  email: " info@jagphotostudios.com",
  // linkedin: "https://linkedin.com",
  // twitter: "https://twitter.com",
  pintarest: " https://www.pinterest.ca/jagphotostudios/",
  facebook: " https://www.facebook.com/jagphotostudios/",
  // twitch: "https://twitch.com",
  youtube:"https://www.youtube.com/@JagPhotoStudios"
};
export {
  meta,
  dataabout,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  images,
  stories,
};
