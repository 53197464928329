import React from "react";
import photo1 from "../../assets/JPEG For DP/nkl.jpg";
import { makeStyles } from "@material-ui/core";

export default function CardILegacy({ pic, heading, price, desc }) {
  const classes = useStyles();
  return (
    <section className={classes.sec}>
      <div className={classes.dataTop}>
        <p className={classes.memoriesText}>YOUR MEMORIES</p>
        <div className={classes.innetData}>
          <p className={classes.legacyText}>Legacy collection</p>
          <hr className={classes.divider} />
          <p className={classes.coverageText}>• 22 HOURS OF COVERAGE</p>
        </div>
      </div>
      <div className={classes.main}>
        <div className={classes.imageWrapperCentered}>
          <img src={photo1} alt="img1" className={classes.image1} />
        </div>
        <div className={classes.data}>
          <ul className={classes.listal}>
            <li>Point 1: Consultation, Planning Tips & Timeline</li>
            <li>Point 2: Consultation, Planning Tips & Timeline</li>
            <li>Point 3: Consultation, Planning Tips & Timeline</li>
            <li>Point 4: Consultation, Planning Tips & Timeline</li>
            <li>Point 5: Consultation, Planning Tips & Timeline</li>
          </ul>
          <hr className={classes.bottomDivider} />
          <p className={classes.priceText}>10,500,- *</p>
          <p className={classes.additionalFeesText}>
            *Additional fees may apply
          </p>
        </div>
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  sec: {
    padding: "30px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "20px",
    },
  },
  dataTop: {
    padding: "20px 0",
    textAlign: "center",
  },
  memoriesText: {
    textAlign: "left",
    fontFamily: "CustomFont3",
    color: "rgb(144, 144, 144)",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  innetData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  legacyText: {
    fontFamily: "CustomFont3",
    fontStyle: "italic",
    fontWeight: "bold",
    margin: "0px",
    color: "black",
    fontSize: "3.1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  divider: {
    width: "200px",
    margin: "0px",
    border: "1px solid gray",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  coverageText: {
    fontFamily: "CustomFont3",
    textTransform: "none",
    fontSize: "18px",
    margin: "0px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "40px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "20px",
    },
  },
  imageWrapperCentered: {
    flex: 1,
    justifyContent: "center",
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
      alignItems: "center",
      display: "flex",
    },
  },
  image1: {
    width: "85%",
    height: "auto",
    objectFit: "cover",
  },
  data: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  listal: {
    listStyle: "none",
    padding: "0",
    margin: "0",
    fontFamily: "CustomFont3",
    textTransform: "none",
    fontSize: "18px",
    color: "rgb(144, 144, 144)",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "center",
    },
  },
  bottomDivider: {
    width: "200px",
    border: "1px solid lightgray",
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  priceText: {
    fontWeight: "bold",
    color: "gray",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  additionalFeesText: {
    color: "rgb(79, 79, 79)",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));
