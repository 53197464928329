import React, { useEffect } from "react";
import InvestmentHead from "../../components/ivestment";
import { meta } from "../../content_option";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Headermain } from "../../header";
import { useDispatch } from "react-redux";
import { allDocument } from "../../features/plans/plans.action";
import Footer from "../../components/footer";
import FollowUs from "../../components/followUs";

export default function Investments() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allDocument());
  }, [dispatch]);
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Investments | {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Headermain />
      {/* <div style={{ marginTop: 10, paddingTop: 20 }}></div> */}
      <div>
        <InvestmentHead />
      </div>
      <FollowUs />
      <Footer />
    </HelmetProvider>
  );
}
