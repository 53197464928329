import { makeStyles } from "@material-ui/core";
import React from "react";
import img from "../../assets/contact.jpeg";

export default function ContactHeroSection() {
  const classes = useStyles();
  return (
    <section className={classes.main}>
      <div className={classes.inner}>
        <div className={classes.textSection}>
          <p className={classes.text}>
            The energy that surrounded you, the essence of your love and the
            emotions that define the day are some of the many things you never
            want to forget—and, here, you never have to. <br />
            <br />
            We want every detail of your wedding to live on in extraordinary
            legacy. Use the form below to reach out, and we’ll be in touch
            shortly.
          </p>
          <p className={classes.inquiryText}>
            For inquiries, please feel free to email us directly at
            <br />
           <a href="mailto:info@jagphotostudios.com" target="_blank">info@jagphotostudios.com</a>  <br />
          </p>
        </div>
        <div className={classes.separator} />
        <div className={classes.imageContainer}>
          <img src={img} alt="" className={classes.image} />
        </div>
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "100px 10px 120px 10px",
    backgroundColor: "#ede9e0",
    display: "flex",
    justifyContent: "center",
  },
  inner: {
    display: "flex",
    columnGap: 60,
    alignItems: "center",
    marginLeft: "30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      rowGap: 60,
      marginLeft: "0px",
    },
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "463px",
    height: "auto",
    padding: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      width: "100%",
      textAlign: "center",
    },
  },
  text: {
    fontSize: "15px",
    marginBottom: "25px",
    lineHeight: "30px",
    textAlign: "justify",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    color: "rgba(102, 102, 102, 1)",
     fontWeight: "400",
     fontFamily: 'Quarto Light, serif',
     letterSpacing: '0.07em'
  },
  inquiryText: {
    fontFamily: 'Quarto Light, serif',
    color: "#77777",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  separator: {
    backgroundColor: "#000",
    width: 1,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "419px",
    height: "494px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      marginTop: "20px", // Add margin to push the image down
    },
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
}));
