import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { Home } from "../pages/home";
import { ContactUs } from "../pages/contact";
import { About } from "../pages/about";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Gallery } from "../pages/gallery";
import FAQ from "../pages/faq";
import { Stories } from "../pages/stories";
import StoryDetails from "../pages/storyDetails";
import { Education } from "../pages/education";
import Login from "../components/login";
import Investments from "../pages/ivestments";
import Illumes from "../pages/illumes";
import PrivateRoute from "../components/privateRoute";
import {Afirmations }from "../pages/afirmations";
const AnimatedSwitch = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 300,
        exit: 300,
      }}
      classNames="page"
      unmountOnExit
    >
      <Switch location={location}>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/faqs" component={FAQ} />
        <Route path="/education" component={Education} />
        <Route path="/stories" component={Stories} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/investments" component={Investments} />
        <Route path="/illumes" component={Illumes} />
        <Route path="/storyDetails/:id" component={StoryDetails} />
        <Route path="/afirmations" component={Afirmations} />
        <Route path="*" component={Home} />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedSwitch />
    </div>
  );
}

export default AppRoutes;
