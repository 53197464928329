import React from "react";
import PropTypes from "prop-types";
import "./styles.css"; // Assuming you have a CSS file for the component

const ImageHorizontal = ({ Src, Height, rotate }) => {
  return (
    <div className="image-container">
      <img
        src={Src}
        alt="FAQ section"
        className={`responsive-image ${rotate ? 'img-rotate' : ''}`}
        style={{ height: Height, opacity: "0.7" }}
      />
    </div>
  );
};

ImageHorizontal.propTypes = {
  Src: PropTypes.string.isRequired,
  Height: PropTypes.string.isRequired,
  rotate: PropTypes.bool, // Ensure height is passed as a prop
};

ImageHorizontal.defaultProps = {
  rotate: false, // Default to false, meaning no rotation
};

export default ImageHorizontal;
