import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import "./styles.css";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
import { Link } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import img from "../../assets/homeslider/JPSW-1724 .webp";
import img1 from "../../assets/homeslider/JT7_4562-2 2.webp";
import img2 from "../../assets/homeslider/JT7_8641-8.webp";
import img3 from "../../assets/homeslider/21.webp";
import img4 from "../../assets/homeslider/22.webp";
import img5 from "../../assets/homeslider/23.webp";
import { Col, Row } from "react-bootstrap";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const writers = [
  {
    image: img1,
    name: "Ramneet - Ave",

    // heading: "Mr and Mrs about their experience with us",
    para1: `Working with Jag Photography was one of the most pleasant experiences from our wedding. They were professional, reliable and attentive to detail. They made us feel comfortable, especially during awkward ‘center of attention’ type moments. Jag and his team bring a sense of quality not only to the photos but also with their level of service. We are extremely happy with the photos from all of our wedding events – these are memories that will last a lifetime because Jag Photography was able to capture every candid moment. The only actual struggle was selecting photos for our photo album because they were all so beautiful! Thank you, Jag Photography.
    `,
   
  },
  {
    image: img2,
    name: "Sandy - Pardip",

    // heading: "We are in absolute awe of the photos",
    para1: `Jag and Jas were incredible throughout our entire wedding week, very punctual and professional in capturing our families. They provided feedback and suggestions when needed to ensure they captured all of the important moment. This duo was fun and easy to work with they truly felt like family by the end of the week. Thank you so much we love all our photos.`,
  },
  {
    image: img,
    name: "Ricky – Simran ",

    // heading: "The wedding pictures were truly everything we dreamed",
    para1: `We took a look at all of the wedding photos and we can't express how happy we are with them. You and your team did such an amazing job in capturing our precious moments that we will cherish forever. We are in absolute awe of all the photos. Thank you so much. You made us feel so comfortable during all shoots, especially the wedding shoot during the heatwave! We appreciate all your hard work. `,
   
  },
  {
    image: img5,
    name: "Suman – Karn ",

    // heading: "The wedding pictures were truly everything we dreamed",
    para1: `We don't even know where to begin! Our wedding pictures were truly everything we dreamed and more. You and your team became family during our wedding week, and it is evident that you are passionate about your work. You were able to balance Karn and I being present and enjoying our week, while getting the most beautiful shots - which was extremely important to us. We are so happy we had you as our photographer, and cannot thank you enough for the memories you captured!`,
    para2:`Thank you from the bottom of our hearts!`,
  },
  {
    image: img4,
    name: "Shaleena – Cris ",

    // heading: "The wedding pictures were truly everything we dreamed",
    para1: `We took a look at all of the wedding photos and we can't express how happy we are with them. You and your team did such an amazing job in capturing our precious moments that we will cherish forever. We are in absolute awe of all the photos. Thank you so much. You made us feel so comfortable during all shoots, especially the wedding shoot during the heatwave! We appreciate all your hard work. `,
    para2:`Choosing Jag Photos as our wedding photographer was one of the best decisions we made for our big day. From the initial consultation to receiving our stunning photos, every step of the process was seamless and enjoyable`,
    para3:`Jag Photos has an incredible talent for capturing the most precious moments in a way that is both artistic and heartfelt. Their attention to detail ensured that every shot was perfect, and they truly went above and beyond to make sure we were comfortable and relaxed throughout the entire shoot.
`,
    para4:`Not only did Jag Photos capture the essence of our love story, but they also managed to capture the emotions and atmosphere of the day beautifully. Looking through our wedding album, we are transported back to each moment, experiencing the joy, laughter, and love all over again.
` ,
   para5:`We are endlessly grateful to Jag Photos for their professionalism, creativity, and passion for their craft. They have provided us with memories that we will cherish for a lifetime, and we cannot recommend them highly enough to anyone in search of a talented and dedicated wedding photographer.`,
   para6:`Thank you, Jag Photos, for exceeding our expectations and for being such an integral part of our special day.`
  },
  {
    image: img3,
    name: "Resh  – Andy ",

    // heading: "The wedding pictures were truly everything we dreamed",
    para1: `Jag and his Team were nothing but professionals. They were so passionate about our wedding, you would have thought they were our family members. Their artistic vision and genuine empathy, makes them the logical choice for weddings. To date, we stay in touch with them and are planing to invite them to future events not only as a vendor but as a family member. They are true professionals and helped captured some really special moments. We love you and the family, Jag! ❤️ `,
   
  },
];

const TestimonalComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // const maxSteps = writers.length;

  // const handleNext = () => {
  //   setActiveStep(activeStep === maxSteps - 1 ? 0 : activeStep + 1);
  // };
  // const handleBack = () => {
  //   setActiveStep(activeStep === 0 ? maxSteps - 1 : activeStep - 1);
  // };

  return (
    <div style={{ backgroundColor: "#d3d6d2", padding: "3em" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Link to="/afirmations">
          <Typography
            variant="p"
            style={{
              textTransform: "uppercase",
              color: "#646464",

              fontWeight: 400,
              fontStyle: " normal",
              fontSize: "9px",
              letterSpacing: ".3em",
              textTransform: "uppercase ",
            }}
          >
            Kind Words
          </Typography>
        </Link>
        <div></div>
        <div></div>
      </Box>

      <div
        className="container testmonal "
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <AutoPlaySwipeableViews
          className={`container abc ${classes.autoPlaySwipeableViews}`}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={10000}
        >
          {writers.map((step, index) => (
            <Box
              key={step.label}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {Math.abs(activeStep - index) <= 2 ? (
                <div className={classes.articleWrapper}>
                  <div className={classes.left}>
                    <img
                      className={classes.img}
                      src={writers[activeStep].image}
                      alt=""
                    />
                    <Typography
                      align="right"
                      className={classes.name}
                      style={{
                        textTransform: "uppercase",
                        color: "#646464",

                        fontWeight: 400,
                        fontStyle: " normal",
                        fontSize: "9px",
                        letterSpacing: ".3em",
                        textTransform: "uppercase ",
                      }}
                    >
                      {writers[activeStep].name}
                    </Typography>
                  </div>
                  <div className={classes.right}>
                    <Typography className={classes.heading}>
                      {writers[activeStep].heading}
                    </Typography>
                    <p className={classes.p}>{writers[activeStep].para1}</p>
                    <p className={classes.p}>{writers[activeStep].para2}</p>
                    <p className={classes.p}>{writers[activeStep].para3}</p>
                    {/* 
                    <div className={classes.info}>
                      <h4 className="km">{writers[activeStep].name}</h4>{" "}
                    </div> */}
                  </div>
                </div>
              ) : null}
            </Box>
          ))}
        </AutoPlaySwipeableViews>

        {/* <Box className={classes.owlInner}>
          {writers.map((i) => (
            // <div className={classes.owlDot}>
            <RadioButtonCheckedIcon />
            // </div>
          ))}
        </Box> */}

        <Box
          sx={{
            padding: "1em 4em 1em 1em",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Link to="/afirmations">
            <Typography
              className={classes.moreBtn}
              style={{
                textTransform: "uppercase",
                color: "#646464",

                fontWeight: 400,
                fontStyle: " normal",
                fontSize: "9px",
                letterSpacing: ".3em",
                textTransform: "uppercase ",
              }}
            >
              Read More Words
            </Typography>
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default TestimonalComponent;

const useStyles = makeStyles((theme) => ({
  testimonalContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "30px",
      width: "100%",
    },
  },
  autoPlaySwipeableViews: {
    "& .react-swipeable-view-container": {
      transition: "transform 1.5s !important",
    },
  },
  testmonal: {
    display: "flex",

    flexDirection: "column",
    gap: "30px",
  },

  container: {
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    "& .react-swipable-view-container": {
      display: "flex",
      justifyContent: "center",
    },
  },

  articleWrapper: {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "911px",
    gap: "10vw",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center",
      gap: "30px",
      width: "auto",
    },
  },
  left: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",

    position: "relative",
    gap: 10,
    "& img": {
      margin: "auto",
      // height: 250,
      width: "360px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "250px",
      },
    },
  },
  right: {
    display: "flex",
    maxWidht: "500px",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    // justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      textAlign: "center",
    },
    "& p": {
      maxWidth: "280px",
    },
  },

  heading: {
    fontSize: "15px",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    color: "rgba(102, 102, 102, 1)",
    fontWeight: "400",
    fontFamily: 'Quarto Light, serif',
    letterSpacing: '0.07em',
    textAlign: "left",
    marginTop: "0em",
    maxWidth: "320px",
  },

  p: {
    fontSize: "12px",
    color: "rgb(79, 79, 79)",
    letterSpacing: ".03em",
    textAlign: "left",
    lineHeight: "22.8px",
    fontWeight: "400",
    paddingTop: "10px",
  },
  name: {
    fontFamily: "CustomFont3",
    fontSize: "16px",
    textTransform: "uppercase",
    lineHeight: "240%",
  },

  moreBtn: {
    fontFamily: "CustomFont",
    textDecoration: "underline",
  },
}));
