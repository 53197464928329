import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import logo from "../../assets/PNG For Water Marks/GMArtboard 2.png";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialPintarest,

} from "react-icons/sl";
import { socialprofils } from "../../content_option";
import { SiYoutube } from "react-icons/si"
const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div className={classes.mainfooter}>
        <div className={classes.links}>
          <Link to="/about" className={classes.link}>
            About
          </Link>
          <Link to="/stories" className={classes.link}>
            Stories
          </Link>
          <div className={classes.logoContainer}>
            <Link to="/" className={classes.logo}>
              <img src={logo} alt="Logo" className={classes.image} />
            </Link>
          </div>
          <Link to="/contact" className={classes.link}>
            Contact
          </Link>
          <Link to="/faqs" className={classes.link}>
          FAQ's
          </Link>
        </div>
        <hr className={classes.divider} />
        <div className={classes.bottom}>
          <div className={classes.SocialIcons}>
            {socialprofils.facebook && (
              <a href={socialprofils.facebook} target="_blank">
                <SlSocialFacebook />
              </a>
            )}
            {socialprofils.instagram && (
              <a href={socialprofils.instagram} target="_blank">
                <SlSocialInstagram />
              </a>
            )}
            {socialprofils.pintarest && (
              <a href={socialprofils.pintarest} target="_blank">
                <SlSocialPintarest />
              </a>
            )}
            {socialprofils.youtube && (
              <a href={socialprofils.youtube} target="_blank"> 
                <   SiYoutube />
              </a>
            )}
          </div>
          <p className={classes.copyright}>
            Jag Photo Studio | All Rights Reserved | Site Credit{" "}
            <a href="https://www.itworx.in/" target="_blank">ITWorx</a>
          </p>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    backgroundColor: "#d3d6d2",
    paddingTop: "20px",
  },
  mainfooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "10px",
    lineHeight: 1.35,
    letterSpacing: "0.15em",
  },
  divider: {
    borderTop: "0.5px solid black",
    width: "80vw",
    marginTop: "30px",
    marginBottom: "10px",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    order: 0, // Default order for larger screens
    [theme.breakpoints.down("sm")]: {
      order: -1, // Move logo to the top on smaller screens
      // marginBottom: "20px",
    },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    maxWidth: "100px", // Adjust as necessary
    height: "auto",
    objectFit: "contain", // Keeps logo aspect ratio intact
  },
  links: {
    display: "flex",
    width: "80%",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#646464",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "15px",
    },
  },
  link: {
    color: '#000',
    fontWeight: 300,
    fontSize: "8px",
    letterSpacing: ".6em",
    textTransform: "uppercase",
    textDecoration: "none",
    "&:hover": {
      color: "#a2a2a2",
    },
  },
  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    color: "#646464",
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  SocialIcons: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2vw",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      gap: "1rem",
    },
  },
  copyright: {
    marginTop: "10px",
    fontSize: "9px",
    color: "#000000",
    textTransform: "uppercase",
    letterSpacing: ".3em",
  },
}));

export default Footer;
