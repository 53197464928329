import { PDE } from "../../http/urls";

export const postLogIn = async (payload) => {
  const res = await PDE.post("api/v1/password-login", payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  return res;
};

export const checkSessionAPI = async () => {
  const res = await PDE.get("api/v1/checksession-client", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("AdminHeaven")}`,
    },
  });
  return res;
};
