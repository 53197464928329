import { combineReducers } from "@reduxjs/toolkit";
import imageUploadSlicer from "../features/imageUpload/imageUpload.slicer";
import loginSlicer from "../features/signIn/signIn.slicer";
import planSlicer from "../features/plans/plan.slicer";
import instagramSlicer from "../features/instagram/instagram.slicer";

const rootReducer = combineReducers({
  imageUpload: imageUploadSlicer,
  LoginSlicer: loginSlicer,
  planSlicer: planSlicer,
  instagramSlicer: instagramSlicer,
});

export default rootReducer;
