import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { meta } from "../../content_option";
import { galleryData } from "./data";
import ReactMasonry from "../../components/masnory";
import { useScrollToTop } from "../../hooks/useScroll";
import { HeaderDark } from "../../header";
import FollowUs from "../../components/followUs";
export const Gallery = () => {
  useScrollToTop();
  return (
    <HelmetProvider>
      <Container className="gallery-header pt-5">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Stories | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <HeaderDark />
        <ReactMasonry data={galleryData} rows="2" />
      </Container>
      <FollowUs />
    </HelmetProvider>
  );
};
