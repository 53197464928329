import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDocuments } from "./api";

export const allDocument = createAsyncThunk("allPlanDocs/docs", async () => {
  try {
    const res = await getAllDocuments();
    console.log(res.data,"asdlfjasldfjsadja")
    return res.data;
  } catch (err) {
    return err.response;
  }
});
