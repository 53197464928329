import React, { useState } from "react";

import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import { Row, Col, Alert } from "react-bootstrap";
import { useFormik } from "formik";
import { contactUsSchema } from "./schema";
import FollowUs from "../../components/followUs";
import { useScrollToTop } from "../../hooks/useScroll";
import { Headermain } from "../../header";
import { Box } from "@material-ui/core";
import Footer from "../../components/footer";
import ContactHeroSection from "../../components/contactHeroSection";
import emailjs from "emailjs-com";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import img1 from "../../assets/contact/contact1.webp";
import img2 from "../../assets/contact/contact2.webp";
import img3 from "../../assets/contact/contact3.webp";
import img4 from "../../assets/contact/contact4.webp";
import img5 from "../../assets/contact/9G4A7914.webp";
import img6 from "../../assets/contact/JPS75589.webp";
import img7 from "../../assets/contact/JPSW-1008.webp";
import img8 from "../../assets/contact/JPSW-1053.webp";
import img9 from "../../assets/contact/JPSW-812.webp";
import img10 from "../../assets/contact/JPSW-884.webp";
import img11 from "../../assets/contact/JPSW-952.webp";


export const contactConfig = {
  YOUR_SERVICE_ID: "service_wrfsckz",
  YOUR_TEMPLATE_ID: "template_ro7pyvn",
  YOUR_USER_ID: "Jay",
  YOUR_PUBLIC_KEY: "B9cyBAp3-wgli6bnK",
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]; // Array of images

export const ContactUs = () => {
  const [formData, setFormdata] = useState({
    loading: false,
    show: false,
    alertmessage: "",
    variant: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const [dateSelected, setDateSelected] = useState(''); 
  useScrollToTop();
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleSubmit = (values) => {
    console.log(values, "aaa");
    setFormdata((prev) => ({ ...prev, loading: true }));

    // Construct the message with only non-empty fields
    const templateParams = {
      from_name: values.email,
      user_name: values.name,
      to_name: contactConfig.YOUR_USER_ID,
      message: `
        Email: ${values.email}\n
        Phone Number: ${values.Phone || 'N/A'}\n
        ${values.InstagramHandle ? `Instagram Handle: ${values.InstagramHandle}\n` : ''}
        ${values.EventDate ? `Event Date: ${values.EventDate}\n` : ''}
        ${values.EventVenue ? `Event Venue: ${values.EventVenue}\n` : ''}
        ${values.findus ? `How they found us: ${values.findus}\n` : ''}
        Event Details: ${values.eventDetails || 'N/A'}
      `,
    };


    emailjs
      .send(
        contactConfig.YOUR_SERVICE_ID,
        contactConfig.YOUR_TEMPLATE_ID,
        templateParams,
        contactConfig.YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormdata({
            loading: false,
            alertmessage: "SUCCESS! Thank you for your message.",
            variant: "success",
            show: true,
          });
        },
        (error) => {
          console.log(error.text);
          setFormdata({
            loading: false,
            alertmessage: `Failed to send! ${error.text}`,
            variant: "danger",
            show: true,
          });
          document.getElementsByClassName("co_alert")[0].scrollIntoView();
        }
      );
  };


  const {
    handleChange,
    handleBlur,
    handleSubmit: formikSubmit,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      Phone: "",
      InstagramHandle: "",
      EventDate: "",
      EventVenue: "",
      findus: "",
      eventDetails: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
      resetForm();
    },
  });

  console.log(errors);

  return (
    <HelmetProvider>
      <div className="contact-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.title} | Contact</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Headermain />
        <ContactHeroSection />
        <div className="form-container">
          <div className="left-image">
            <AutoPlaySwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}

            >
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image} alt={`Slide ${index}`} className="form-image" />
                </div>
              ))}
            </AutoPlaySwipeableViews>
            {/* <img
              src={img1} // add the correct image path
              alt="Event"
              className="form-image"
            /> */}
          </div>

          <div className="right-form">
            <Alert
              variant={formData.variant}
              className={`rounded-0 co_alert ${formData.show ? "d-block" : "d-none"
                }`}
              onClose={() => setFormdata({ show: false })}
              dismissible
            >
              <p className="my-0">{formData.alertmessage}</p>
            </Alert>

            <form onSubmit={formikSubmit} className="contact__form">
              <Row>
                <Col lg="12" className="form-group">
                  <input
                    className={`form-control rounded-0  custom-placeholder ${touched.name && errors.name ? 'is-invalid' : ''}`}
                    id="name"
                    name="name"
                    placeholder="First Name *"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.name && errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </Col>

                <Col lg="6" className="form-group">
                  <input
                    className={`form-control custom-placeholder ${touched.email && errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    name="email"
                    placeholder="Email *"
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </Col>

                {/* Phone Number */}
                <Col lg="6" className="form-group">
                  <input
                    className={`form-control  custom-placeholder ${touched.Phone && errors.Phone ? 'is-invalid' : ''}`}
                    id="Phone"
                    name="Phone"
                    placeholder="Phone Number *"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.Phone && errors.Phone && (
                    <div className="text-danger">{errors.Phone}</div>
                  )}
                </Col>
                <Col lg="12" className="form-group">
                  {/* Conditionally hide the label on mobile */}
                  {!isFocused && !dateSelected && (
                    <label className="mobile-label">Event Date *</label>
                  )}

                  {/* Desktop Input */}
                  <input
                    className={`hide-d form-control rounded-0 custom-placeholder ${touched.EventDate && errors.EventDate ? 'is-invalid' : ''}`}
                    name="EventDate"
                    placeholder="Event Date *"
                    type="text"
                    onFocus={(e) => {
                      e.target.type = 'date'; // Switch to "date" on focus
                      e.target.showPicker(); // Show the date picker
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      if (!e.target.value) e.target.type = 'text';
                    }}
                    onChange={handleChange}
                  />
                  {touched.EventDate && errors.EventDate && (
                    <div className="hide-d text-danger">{errors.EventDate}</div>
                  )}

                  {/* Mobile Input */}
                  <input
                    className={`hide-m form-control rounded-0 custom-placeholder ${touched.EventDate && errors.EventDate ? 'is-invalid' : ''}`}
                    name="EventDate"
                    placeholder="Event Date *"
                    type="date"
                    onFocus={() => setIsFocused(true)} // Hide the label when focused
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsFocused(false); // Show the label again on blur if no date is selected
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setDateSelected(e.target.value); // Track the selected date
                    }}
                  />
                  {touched.EventDate && errors.EventDate && (
                    <div className="hide-m text-danger">{errors.EventDate}</div>
                  )}
                  </Col>


                  <Col lg="12" className="form-group">
                    <input
                      className="form-control custom-placeholder"
                      name="EventVenue"
                      placeholder="Event Venue"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col lg="12" className="form-group">
                    <input
                      className="form-control custom-placeholder"
                      name="InstagramHandle"
                      placeholder="Instagram Handle"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col lg="12" className="form-group">
                    <input
                      className="form-control custom-placeholder"
                      name="findus"
                      placeholder="How did you find us?"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col lg="12" className="form-group">
                    <textarea
                      className={`form-control rounded-0 form-color custom-placeholder ${touched.eventDetails && errors.eventDetails ? 'is-invalid' : ''}`}
                      name="eventDetails"
                      placeholder="Details about the event *"
                      rows="3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    {touched.eventDetails && errors.eventDetails && (
                      <div className="text-danger">{errors.eventDetails}</div>
                    )}
                  </Col>

                  <Col lg="12" className="form-group">
                    <button className="btn submitBtn btn-cls" type="submit">
                      {formData.loading ? "Sending..." : "Submit Form"}
                    </button>
                  </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
      <div className={formData.loading ? "loading-bar" : "d-none"}></div>
      <FollowUs />
      <Footer />
    </HelmetProvider>
  );
};
