import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import "./App.css";
import Preloader from "./preloader.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./scrollToTop";
import Footer from "../components/footer";
import { Provider } from "react-redux";
import store from "../redux/store.js";

import { Toaster } from "react-hot-toast";
import HooksWrapper from "../hookWrapper.js";

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 6000,
      offset: 200,
      easing: "ease-in-out",
      delay: 6000,
      once: true,
    });

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    setTimeout(() => {
      document.querySelector("img").style.opacity = 1;
    }, 500); // set opacity of image to 1 after 500ms delay

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Provider store={store}>
        <Toaster />
        <HooksWrapper>
          <Router basename={process.env.PUBLIC_URL}>
            {loading ? (
              <Preloader />
            ) : (
              <div>
                {/* <Headermain /> */}
                <AppRoutes />
                
              </div>
            )}
          </Router>
          <ScrollToTop />
        </HooksWrapper>
      </Provider>
    </>
  );
}
