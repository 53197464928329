import React from "react";
import Horizontal from "../../assets/moments.webp";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  firstSentence: {
    fontFamily: 'Silk Serif ExtraLight, serif',
    fontSize: "4rem",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
}));

const Moments = () => {
  const classes = useStyles();

  return (
    <Box sx={{ position: "relative", height: "500px" }}>
      <img src={Horizontal} alt="Export" className={classes.image} />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "white",
        }}
      >
        <Typography className={classes.firstSentence}>
          These are the moments
        </Typography>
        <Typography className={classes.firstSentence}>
          you'll never forget
        </Typography>
      </Box>
    </Box>
  );
};

export default Moments;
