import { PDE } from "../../http/urls";

export const getAllDocuments = async () => {
  const res = await PDE.get("api/v1/featuredimages");
  return res;
};

export const getDocumentById = async (id) => {
  const res = await PDE.get(`api/v1/featuredimages/${id}`);
  return res;
};
