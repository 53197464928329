import React from "react";
import { Box } from "@material-ui/core";

const InsideWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        padding: {
          xs: "0px 1em", // Extra-small devices (portrait phones)
          sm: "0px 2em", // Small devices (landscape phones)
          md: "0px 3em", // Medium devices (tablets)
          lg: "0px 4em", // Large devices (desktops)
          xl: "0px 5em", // Extra-large devices (large desktops)
        },
      }}
    >
      {children}
    </Box>
  );
};

export default InsideWrapper;
