import React, { useState, useEffect } from "react";
import SingleQuestion from "../../components/faq/index"; // Import the modified component
import QAdata from "./question";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./styles.css";
import { meta } from "../../content_option";
import AOS from "aos";
import "aos/dist/aos.css";
import { useScrollToTop } from "../../hooks/useScroll";
import { Headermain } from "../../header";
import Footer from "../../components/footer";
import FollowUs from "../../components/followUs";
import ImageHorizontal from "../../components/imageHorizontal";
import Horizontal from "../../assets/faqImg.jpeg";

const FAQ = () => {
  const [questions, setQuestions] = useState(QAdata); // Question data from QAdata
  useScrollToTop();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <HelmetProvider>
      <div className="faq-header ">
        <Helmet>
          <meta charSet="utf-8" />
          <title> FAQ's | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Headermain />
        <div className="faqHeading cntnr  mt-5  pt-5 pb-5">
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Silk Serif ExtraLight, serif",
              textTransform: "none",
              fontSize: "30px",
              color: "rgb(144, 144, 144)",
            }}
          >
            Any Question in mind?
          </h3>
        </div>

        <section className="container mt-5">
          {questions.map(({ topic, questions, qid }) => {
            return (
              <div className="questionSection" key={qid}>
                {questions.map((question, id) => (
                  <SingleQuestion key={id} {...question} />
                ))}
              </div>
            );
          })}
        </section>
        <ImageHorizontal Src={Horizontal} Height={"800px"} />
        <FollowUs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FAQ;
