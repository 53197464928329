

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import bg1 from '../../assets/sliderImages/slider1.jpg';
import bg2 from '../../assets/sliderImages/slider2.jpg';
import bg3 from '../../assets/sliderImages/slider3.jpg';
import { Height } from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import img1 from "../../assets/contact/contact1.webp";
import img2 from "../../assets/contact/contact2.webp";
import img3 from "../../assets/contact/contact3.webp";
import img4 from "../../assets/contact/contact4.webp";
import img5 from "../../assets/contact/9G4A7914.webp";
import img6 from "../../assets/contact/JPS75589.webp";
import img7 from "../../assets/contact/JPSW-1008.webp";
import img8 from "../../assets/contact/JPSW-1053.webp";
import img9 from "../../assets/contact/JPSW-812.webp";
import img10 from "../../assets/contact/JPSW-884.webp";
import img11 from "../../assets/contact/JPSW-952.webp";
import './type.css'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const images = [img1, img2, img3, img4]; // Array of images
const images1 = [img5,img6,img7,img8];
const images2 = [img3,img9,img10,img11];
export default function AboutTextSection() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const backgrounds = [bg1, bg2, bg3, bg1, bg2, bg3];
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <>
    <h2 className={classes.title}>Our Approach</h2>     
    <section className={classes.main}>
      <div className={classes.contentContainer}>
        {/* Section 1 */}
        <div className={classes.section}>
          <div className={classes.textSection}>
            <p className={classes.text}>
              At Jag Photo Studios, we understand that every love story is as unique as the couple it belongs to. That's why our approach to photography goes beyond just taking pictures– it's about capturing the essence of your relationship, the depth of your connection, and the beauty of your journey together.
            </p>
          </div>
          <div className={classes.imageSection}>
          {/* <AutoPlaySwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}
              
            >
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image} alt={`Slide ${index}`} className={classes.image} />
                </div>
              ))}
            </AutoPlaySwipeableViews> */}
            <img src={img3} alt="Static Image" className={classes.image} />
          </div>
        </div>

        {/* Section 2 */}
        <div className={classes.section}>
          <div className={classes.imageSection}>
          {/* <AutoPlaySwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}
              
            >
              {images1.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image} alt={`Slide ${index}`} className={classes.image} />
                </div>
              ))}
            </AutoPlaySwipeableViews> */}
            <img src={img1} alt="Static Image" className={classes.image} />
          </div>
          <div className={classes.textSection}>
            <p className={classes.text}>
              <strong className="prosonalized">Personalized Consultation:</strong> We believe in getting to know you as a couple before the big day. Through personalized consultations, we learn about your love story, your vision for the wedding, and any special moments you want to capture.
            </p>
            <p className={classes.text}>
              <strong className="prosonalized">Unobtrusive Presence:</strong> On your wedding day, we aim to be a seamless part of the festivities, blending into the background while capturing candid moments and genuine emotions.
            </p>
          </div>
        </div>

        {/* Section 3 */}
        <div className={classes.section}>
          <div className={classes.textSection}>
            <p className={classes.text}>
              <strong className="prosonalized"> Attention to Detail:</strong> From the intricate details of your décor to the fleeting glances exchanged between you and your partner, we pay attention to every nuance, ensuring that no moment goes unnoticed or unrecorded.
            </p>
            <p className={classes.text}>
              <strong className="prosonalized"> Artistic Vision:</strong> We approach each wedding with a creative eye, seeking out unique angles, lighting, and compositions to create images that are not only beautiful but also timeless works of art.
            </p>
          </div>
          <div className={classes.imageSection}>
          {/* <AutoPlaySwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}
              
            >
              {images2.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image} alt={`Slide ${index}`} className={classes.image} />
                </div>
              ))}
            </AutoPlaySwipeableViews> */}
            <img src={img2} alt="Static Image" className={classes.image} />
          </div>
        </div>

        {/* Add more sections as needed */}
      </div>
    </section>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "110px 10px",
    // backgroundColor: "#ede9e0",
    display: "flex",
    justifyContent: "center",
  },
  contentContainer: {
    maxWidth: "1200px",
    width: "100%",
    width: "1000px",
    [theme.breakpoints.down("sm")]: {
      width: "300px", // Applies for screens 768px and below
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: "0", // Removes margin-left for screens 768px and below
    },
    gap: "6em",
    marginLeft: "30px",
  },
  textSection: {
    flex: 1,
    // paddingRight: "50px",
    // paddingLeft: "50px",
    borderRadius: "8px",
    lineHeight: "1.6",
    // marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
      marginBottom: "20px",
    },
    // marginLeft: "20px"
  },
  title: {
    fontSize: "80px",
    fontWeight: "400",
    marginBottom: "50px",
    marginTop: "50px",
    color: "#333",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      
    },
    color: "rgb(79, 79, 79)",
    fontFamily: 'Silk Serif Medium, serif',
  },
  text: {
    fontSize: "15px",
    marginBottom: "25px",
    lineHeight: "30px",
    textAlign: "justify",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      padding: "0px 20px"
    },
    color: "rgba(102, 102, 102, 1)",
     fontWeight: "400",
     fontFamily: 'Quarto Light, serif',
     letterSpacing: '0.07em'
  },
  imageSection: {
    // flex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width:"419px",
    // height:"494px" ,
    [theme.breakpoints.down("sm")]: {
      width:"100%",
      
    },
  },
  image: {
    width: "100%",
    borderRadius: "8px",
    objectFit: "cover",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
