import React, { useEffect, useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { addLogIn } from "../../features/signIn/signIn.action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const { isAuthenticated } = useSelector((state) => state.LoginSlicer);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      password,
    };
    await dispatch(addLogIn(payload));
    setPassword("");
  };

  useEffect(() => {
    if (isAuthenticated) {
      // return <Redirect to={"/investments"} />;
      history.push("/investments");
    }
  }, [isAuthenticated, history]);

  return (
    <section className={classes.main}>
      <div className={classes.dataDev}>
        <div>
          <LockIcon className={classes.IconLock} />
        </div>
        <div>
          <p className={classes.para}>You need a password to view this page.</p>
        </div>
        <form>
          <div>
            <input
              className={classes.inputLogin}
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <div>
              <button className={classes.btnLogin} onClick={handleSubmit}>
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#ececec",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  dataDev: {
    width: 400,
    margin: "auto",
    textAlign: "center",
    padding: 0,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "30px",
    },
  },
  para: {
    paddingTop: 5,
    color: "#727272",
  },
  IconLock: {
    color: "#727272",
  },
  inputLogin: {
    width: "100%",
    padding: "  8px",
    borderRadius: 5,
    color: "#727272",
  },
  btnLogin: {
    marginTop: 10,
    backgroundColor: "#494949",
    border: "none",
    color: "#fff",
    cursor: "pointer",
    fontWeight: "100",
    width: "100%",
    height: "42px",
    textTransform: "uppercase",
  },
}));
