import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function InvesmentsCardA({ item }) {
  const classes = useStyles();
  return (
    <Link to="/illumes" className={classes.link}>
      <section className={classes.main}>
        <div className={classes.cardmain}>
          <img src={item?.imageUrl} alt="img1" className={classes.image1} />
          <div className={classes.textcard}>
            <p className={classes.price}>Starting from ${item?.price}</p>
            <p className={classes.title}>{item?.title}</p>
          </div>
        </div>
      </section>
    </Link>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px",
    },
  },
  cardmain: {
    width: '100%',
    "&:hover": {
      backgroundColor: "#d3d6d2",
      transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  image1: {
    width: '100%',
    height: 'auto',
  },
  textcard: {
    padding: "20px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  price: {
    fontSize: "18px",
    color: "#C6884A",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000",
  },
  link: {
    textDecoration: "none",
  },
}));
