import { makeStyles } from "@material-ui/core";
import React from "react";
import img from "../../assets/Jagdeep.jpg";

export default function AboutHeroSection() {
  const classes = useStyles();
  return (
    <section className={classes.main}>
      <div className={classes.inner}>
        <div className={classes.textSection}>
          <p className={classes.text}>
            Jag brings a global perspective to his photography, treating every shot as a masterpiece and elevating each moment with his unique vision. For nearly a decade, Jag Photo Studios has been entrusted with capturing life’s most precious memories, from luxurious weddings to bespoke portraits. As a founder, his engineering background enhances his photographic expertise, the studio excels at documenting authentic, emotive experiences. Each image strikes a delicate balance between artistry and technical precision, resulting in timeless visuals that tell a compelling story.
            Jag’s deep commitment to his craft shines through in his focus on preserving genuine emotions and atmospheres, capturing real moments in real places. His meticulous attention to detail, paired with a passion for storytelling, produces images that not only showcase the beauty of the moment but also evoke the powerful emotions and memories tied to it. Whether it's the joy of a wedding, the warmth of family bonds, or the serene beauty of nature, Jag Photo Studios offers more than just photographs—they deliver an immersive experience.
            While Jagdip’s artistry is at the heart of his work, his true priority lies in forging meaningful connections with clients and providing an unforgettable experience. Each wedding is an opportunity to craft images that resonate deeply, leaving a lasting impression. Whether documenting grand celebrations or capturing nature’s quiet beauty, Jagdip’s work is driven by a desire to make the world a little more beautiful, one image at a time.
          </p>
        </div>
        <div className={classes.separator} />
        <div className={classes.imageContainer}>
          <img src={img} alt="" className={classes.image} />
          <p className={classes.text1}> Jagdip Thandi</p>
          <span className={classes.text2}>Founder</span>
          {/* <div className={classes.caption}>
            <div className={classes.headFounder}>
              <h5 className={classes.founderName}>Amrit Grewal</h5>
            </div>
            <div>Founder</div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "100px 10px 120px 10px",
    backgroundColor: "#ede9e0",
    display: "flex",
    justifyContent: "center",
  },
  inner: {
    display: "flex",
    columnGap: 60,
    alignItems: "center",
    marginLeft: "30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      rowGap: 60,
      marginLeft: "0px",
    },
  },
  textSection: {
    width: "463px",
    height: "auto",
    padding: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      width: "100%",
    },
  },
  text: {
    fontSize: "15px",
    marginBottom: "25px",
    lineHeight: "30px",
    textAlign: "justify",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    color: "rgba(102, 102, 102, 1)",
    fontWeight: "400",
    fontFamily: 'Quarto Light, serif',
    letterSpacing: '0.07em'
  },
  text1: {
    fontSize: "25px",
    marginBottom: "25px",
    lineHeight: "30px",
    textAlign: "justify",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    color: "rgba(102, 102, 102, 1)",
    fontWeight: "400",
    fontFamily: 'Quarto Light, serif',
    letterSpacing: '0.07em',
    marginTop: "20px",
    marginBottom: "0",
    textAlign: "center",

  },
  text2: {
    fontSize: "13px",
    marginBottom: "25px",
    lineHeight: "30px",
    textAlign: "justify",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    color: "rgba(102, 102, 102, 1)",
    fontWeight: "400",
    fontFamily: 'Silk Serif Light Italic',
    letterSpacing: '0.07em',
    // marginTop: "10px",
    textAlign: "center",

  },
  separator: {
    backgroundColor: "#000",
    width: 1,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "419px",
    height: "494px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  caption: {
    display: "flex",
    flexDirection: "column",
    textAlign: "end",
    paddingTop: 8,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  headFounder: {
    paddingTop: "10px",
  },
  founderName: {
    textTransform: "uppercase",
    margin: 0,
  },
}));
