import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { meta } from "../../content_option";
import StoryCard from "../../components/storyCards";
import { useHistory } from "react-router-dom";
import { useScrollToTop } from "../../hooks/useScroll";
import { Headermain } from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { allDocument } from "../../features/imageUpload/imageUpload.action";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import Footer from "../../components/footer";
import FollowUs from "../../components/followUs";
import StoriesHeroSection from "../../components/storiesHeroSection";

const useStyles = makeStyles((theme) => ({
  heroSectionWrapper: {
    marginBottom: "6em", // Adds space between the hero section and the cards
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "1em",
  },
  stories: {
    display: "flex",
    justifyContent: "flex-start", // Align items to the start of the row
    // alignItems: "center",
    flexWrap: "wrap", // Allow wrapping to the next row
    gap: "5vw", // Space between cards
    marginBottom: "3rem",
    maxWidth: "1425px", // Restrict the maximum width of the container to keep it centered
    margin: "0 auto", // Center the entire container horizontally
    // [theme.breakpoints.down("lg")]: {
    //   maxWidth: "1285px",
    //   gap: "1vw"
    // },
    [theme.breakpoints.down("md")]: {
      maxWidth: "900px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  cardContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    margin: "20px", // Space around each card
    flexBasis: "30%", // Each card takes up 1/3 of the row (30% + margins)
    transition: "transform 0.3s", // Smooth hover effect
    "&:hover": {
      transform: "scale(1.02)", // Slight zoom effect on hover
    },
    "& img": {
      height: "370px", // Adjust the image height
      width: "280px", // Adjust the image width
    },
  },
  divTitle: {
    padding: "220px 10px",
    backgroundColor: "#fff",
    opacity: 0,
    position: "absolute",
    textAlign: "center",
    top: "50px",
    left: "50px",
    right: "50px",
    bottom: "50px",
    transition: "opacity 0.3s",
    "&:hover": {
      opacity: 0.8,
    },
  },
  divTitleText: {
    fontFamily: '"Playfair Display", sans-serif',
    padding: "7px 0",
    fontSize: "1.45rem",
    color: "#c6884a",
  },
}));

export const Stories = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { allDoc, allDocLoading, allDocLoadingSucess, allDocLoadingFailed } =
    useSelector((state) => state.imageUpload);

  useScrollToTop();

  const showDetails = (key) => {
    history.push(`/${key}`);
  };

  useEffect(() => {
    dispatch(allDocument());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Stories | {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Headermain />
      <div className={classes.heroSectionWrapper}>
        <StoriesHeroSection />
      </div>
      <Box style={{ paddingBottom: "1em" }}>
        <div className={classes.container}>
          <div className={classes.stories}>
            {allDocLoading && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {allDocLoadingFailed && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {allDocLoadingSucess &&
              allDoc?.map((item, key) => (
                <StoryCard
                  {...item}
                  key={key}
                  code={item.title}
                  showDetails={showDetails}
                />
              ))}
          </div>
        </div>
      </Box>
      <FollowUs />
      <Footer />
    </HelmetProvider>
  );
};
