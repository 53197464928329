import { createSlice } from "@reduxjs/toolkit";
import { allDocument } from "./plans.action";

const initialState = {
  allDoc: [],
  allDocLoading: false,
  allDocLoadingSucess: false,
  allDocLoadingFailed: false,
};

const planSlicer = createSlice({
  name: "plans/get",
  initialState: initialState,

  extraReducers: {
    [allDocument.pending]: (state) => {
      state.allDocLoading = true;
      state.allDocLoadingSucess = false;
      state.allDocLoadingFailed = false;
    },
    [allDocument.fulfilled]: (state, action) => {
      state.allDocLoadingSucess = true;
      state.allDocLoadingFailed = false;
      state.allDocLoading = false;
      state.allDoc = action.payload;
    },
    [allDocument.rejected]: (state) => {
      state.allDocLoadingFailed = true;
      state.allDocLoadingSucess = false;
      state.allDocLoading = false;
    },
  },
});

export default planSlicer.reducer;
