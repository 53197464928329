import React, { useState } from "react";
import "./style.css";
import { VscMenu, VscClose } from "react-icons/vsc";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/PNG For Water Marks/GMArtboard 2 copy@2x.png";
import logo1 from "../assets/PNG For Water Marks/GMArtboard 2.png";
export const Headermain = () => {
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };
  return (
    <>
      <header className="site__header">
        <div className="headerContainer">
          <ul className="menu-left2">
            <li className="item">
              <Link to="/about" className="navItem">
                About
              </Link>
            </li>
            <li className="item">
              <Link to="/Stories" className="navItem">
                {" "}
                Stories
              </Link>
            </li>
            {/* <li className="item" to="/education">
              <Link to="/education" className="navItem">
                {" "}
                Education
              </Link>
            </li> */}
          </ul>
          <div className="logo">
            <Link to="/">
              <img src={logo1} alt="" className="b" />
            </Link>
          </div>

          <ul className="menu2">
            <li className="item">
              <Link to="/contact" className="navItem">
                {" "}
                Contact
              </Link>
            </li>
            <li className="item">
              <Link to="/faqs" className="navItem">
                {" "}
                FAQ's
              </Link>
            </li>
          </ul>

          <button className="menu__button  nav_ac" onClick={handleToggle}>
            {!isActive ? <VscClose className="close-icon" /> : <VscMenu />}
          </button>
        </div>

        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  {/* <li className="menu_item ">
                    <Link onClick={handleToggle} to="/" className="my-3">
                      Home
                    </Link>
                  </li> */}
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/about" className="my-3">
                      About
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/Stories" className="my-3">
                      {" "}
                      Stories
                    </Link>
                  </li>
                  <li className="menu_item" to="/education">
                    <Link to="/education" className="my-3">
                      {" "}
                      Education
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/contact" className="my-3">
                      {" "}
                      Contact
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/faqs" className="my-3">
                      {" "}
                      FAQ's
                    </Link>
                  </li>
                  {/* <li className="menu_item">
                    <Link onClick={handleToggle} to="/" className="my-3">
                      {" "}
                      Blog
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export const HeaderDark = () => {
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };
  return (
    <>
      <header className=" site__headerDark">
        <div className="headerContainerDark">
          <ul className="menu-left">
            <li className="item">
              <Link to="/about" className="navItem dark">
                About
              </Link>
            </li>
            <li className="item">
              <Link to="/Stories" className="navItem dark">
                {" "}
                Stories
              </Link>
            </li>
            {/* <li className="item">
              <Link to="/education" className="navItem dark">
                {" "}
                Education
              </Link>
            </li> */}
          </ul>
          <div className="logo">
            <Link to="/">
              <img src={logo1} alt="" className="b" />
            </Link>
          </div>

          <ul className="menu">
            <li className="item">
              <Link to="/contact" className="navItem dark">
                {" "}
                Contact
              </Link>
            </li>
            <li className="item">
              <Link to="/faqs" className="navItem dark">
                {" "}
                FAQ's
              </Link>
            </li>
          </ul>

          <button className="menu__button  nav_ac dark" onClick={handleToggle}>
            {!isActive ? <VscClose className="close-icon" /> : <VscMenu />}
          </button>
        </div>

        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/about" className="my-3">
                      About
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/Stories" className="my-3">
                      {" "}
                      Stories
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/contact" className="my-3">
                      {" "}
                      Contact
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/faqs" className="my-3">
                      {" "}
                      FAQ's
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
