var youtubeThumbnail = require("youtube-thumbnail");

var thumbnail = youtubeThumbnail("");

export const galleryData = [
  {
    id: 1,
    url: "https://www.youtube.com/watch?v=7l_DRsA9MrY",
    img: thumbnail.high.url,
    desctiption: "Him + Her",
    link: "#",
  },
  {
    id: 2,
    url: "https://youtu.be/WalpO_pIE_E",
    img: thumbnail.high.url,
    desctiption: "Never ending story of two souls",
    link: "#",
  },
  {
    id: 3,
    url: "https://youtu.be/ae_3QtMOGkc",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.wre",
    link: "#",
  },
  {
    id: 4,
    url: "https://www.youtube.com/watch?v=7l_DRsA9MrY",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials. werw",
    link: "#",
  },
  {
    id: 5,
    url: "https://youtu.be/ae_3QtMOGkc",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials. 1213",
    link: "#",
  },
  {
    id: 6,
    url: "https://www.youtube.com/watch?v=7l_DRsA9MrY",
    img: thumbnail.high.url,
    desctiption: "Him + Her 324",
    link: "#",
  },
  {
    id: 7,
    url: "https://youtu.be/WalpO_pIE_E",
    img: thumbnail.high.url,
    desctiption: "Never ending story of two souls 56",
    link: "#",
  },
  {
    id: 8,
    url: "https://youtu.be/ae_3QtMOGkc",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.45",
    link: "#",
  },
  {
    id: 9,
    url: "https://www.youtube.com/watch?v=7l_DRsA9MrY",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.4",
    link: "#",
  },
  {
    id: 10,
    url: "https://youtu.be/ae_3QtMOGkc",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.3",
    link: "#",
  },
  {
    id: 11,
    url: "https://youtu.be/ae_3QtMOGkc",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials. 2",
    link: "#",
  },
  {
    id: 12,
    url: "https://youtu.be/ae_3QtMOGkc",
    img: thumbnail.high.url,
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials. 1",
    link: "#",
  },
];
