import { object, string, number } from "yup";

export const contactUsSchema = object().shape({
  name: string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),

  email: string()
    .required("Email is required")
    .email("Please enter a valid email address with '@' and '.'"),

  Phone: string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),

  InstagramHandle: string(), // Optional

  EventDate: string()
    .required("Event date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Please enter a valid date in YYYY-MM-DD format"),

  EventVenue: string(), // Optional

  findus: string(), // Optional

  eventDetails: string()
    .required("Please provide details about the event")
    .min(10, "Event details must be at least 10 characters long"),
});
