import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom"; // Import useHistory for navigation
import "./style.css";

const Question = ({ question, answer }) => {
  const [showInfo, setShowInfo] = useState(false);
  const history = useHistory(); // Hook to navigate programmatically

  // Function to handle answer click
  const handleAnswerClick = (answer) => {
    if (answer === "Please inquire for more information.") {
      history.push("/contact"); // Navigate to the contact page
    }
  };

  return (
    <article className="question">
      <div className="singleQuestion">
        <h4 style={{ textTransform: 'none', fontSize: '14px', color: 'rgb(97 76 76)', fontStyle: "italic" }}>
          {question}
        </h4>
        <p
          style={{
            fontFamily: 'Quarto Light, serif',

            fontSize: '14px',
            cursor: answer === "Please inquire for more information." ? "pointer" : "default",
            color: answer === "Please inquire for more information.",
          }}
          onClick={() => handleAnswerClick(answer)} // Attach click handler
        >
          {answer}
        </p>
      </div>
    </article>
  );
};

export default Question;
